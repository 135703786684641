import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import {THEME_COLORS} from "./vuetify";

Vue.use(VueSweetalert2, {
    confirmButtonColor: THEME_COLORS.alert.confirmButtonColor,
    cancelButtonColor: THEME_COLORS.alert.cancelButtonColor
});