import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ALTERAR_SENHA: 'AlterarSenha'
}

export default [
    {
        name: ROUTER_NAMES.ALTERAR_SENHA,
        path: '/alterar-senha',
        meta: {
            menu: MENUS.SISTEMA,
            title: 'Alterar Senha',
            searchAlias: 'Alterar Senha',
            searchDescription: 'Alterar Senha',
            searchTerms: ['alterar','senha'],
            hasRole: 'role/alterarSenha'
        },
        component: () => import('@/views/AlterarSenha')
    },
]