import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    REGIAO: 'Regiao'
}

export default [
    {
        name: ROUTER_NAMES.REGIAO,
        path: '/regiao',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Região',
            searchAlias: 'Região',
            searchDescription: 'Veja todas as Regiões cadastradas',
            searchTerms: ['Região'],
            hasRole: 'role/regiao'
        },
        component: () => import('@/views/Regiao')
    },
]