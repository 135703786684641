import Vue from 'vue';
import {validateBr} from 'js-brasil';

import moment from 'moment';

function isEmptyString(v) {
    return v == null || v === '';
}

function isEmptyArray(v) {
    return v == null || !(v instanceof Array) || v.length === 0;
}

const stringValidators = {
    required(value, message = 'Campo Obrigatorio') {
        function validate() {
            return !!value || message
        }
        return validate();
    },
    requiredIf(value, condition, message = 'Campo Obrigatorio') {
        function validate() {
            return condition ? (!!value || message) : true;
        }
        return validate();
    },
    notEmpty(value, message = 'Campo Obrigatorio') {
        function validate() {
            return !!value && value.trim().length > 0 || message
        }
        return validate();
    },
    greaterThanOrEquals(value, min, message = `Deve ter no minimo ${min} caracteres`) {
        function validate() {
            return value == null || value === '' || (value.length >= min || message);
        }
        return validate();
    },
    lessThanOrEquals(value, max, message = `Deve ter no máximo ${max} caracteres`) {
        function validate() {
            return value == null || value === '' || (value.length <= max || message);
        }
        return validate();
    },

    email(value, message = 'email inválido') {
        function validate() {
            return value == null || value === '' ||  ( /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(value) || message);
        }
        return validate();
    },
    notPresentIn(value, list, message = 'Item já existe') {
        function validate() {
            return isEmptyString(value) || isEmptyArray(list) || list.find(i => i.toLowerCase() === value.toLowerCase()) == null || message
        }
        return validate();
    },
    exactLength(value, length, message = `Deve ter exatamente ${length} caracteres`) {
        function validate() {
            return value == null || value === '' || value.length === length || message;
        }
        return validate();
    },
    cpf(value, message = 'CPF Inválido') {
        function validate() {
            return value == null || value === '' || value === '999.999.999-99' || validateBr.cpf(value) || message;
        }
        return validate();
    },
    cnpj(value, message = 'CNPJ Inválido') {
        function validate() {
            return value == null || value === '' || value === '99.999.999/9999-99' || validateBr.cnpj(value) || message;
        }
        return validate();
    },    
    camposIguais(campo1, campo2, message = 'Senhas Não conferem') {
        function validate() {
            return campo1 === campo2 || message;
        }
        return validate();
    }
}

const arrayValidators = {
    required(value, message = 'Campo obrigatorio') {
        function validate() {
            return (value != null && value instanceof Array && value.length > 0) || message;
        }

        return validate();
    }
}

const numberValidators = {
    required(value, message = 'Campo Obrigatorio') {
        function validate() {
            return !!value || message
        }
        return validate();
    },
    greaterThanOrEquals(value, min, message = `Deve ser maior ou igual que ${min}`) {
        function validate() {
            return value == null || (value >= min || message);
        }
        return validate();
    },
    lessThanOrEquals(value, max, message = `Deve ser menor ou igual que ${max}`) {
        function validate() {
            return value == null || (value <= max || message);
        }
        return validate();
    },
    integerOnly(value, message) {
        function validate() {
            function isInt(value) {
                return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
            }
            return value == null || (isInt(value) || message);
        }
        return validate();
    },
    tamanhoMaxArquivo(value, max, message = `O arquivo deve ter no máximo ${max}MB`) {
        function validate() {
            return value == null || value === '' || (value.size <= max * 1024 * 1024 || message);
        }
        return validate();
    },    
}

const timeValidators = {
    isAfter(value, afterOf, message = `Deve ser depois de ${afterOf}`) {
        function validate() {

            const _startTime = moment(value, 'HH:mm');
            const _endTIme = moment(afterOf, 'HH:mm');

            return value == null || _startTime.isAfter(_endTIme) || message;
        }
        return validate();
    },

    isBefore(value, beforeOf, message = `Deve ser antes de ${beforeOf}`) {
        function validate() {

            const _startTime = moment(value, 'HH:mm');
            const _endTIme = moment(beforeOf, 'HH:mm');

            return value == null || _startTime.isBefore(_endTIme) || message;
        }
        return validate();
    }
}

Vue.prototype.$validators = {
    string: stringValidators,
    array: arrayValidators,
    number: numberValidators,
    time: timeValidators,
    untilIsTrue(value, message) {
        return !value || message
    },
    notNullOrUndefined(value, message = 'Campo obrigatorio') {
        function validate() {
            return value != null || message;
        }
        return validate();
    },
    notNullOrUndefinedWhen(value, condition, message = 'Campo obrigatorio') {
        function validate() {
            return condition ? value != null || message : true;
        }
        return validate();
    }
}