import Vue from 'vue';
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
};


Vue.use(Toast, options);

Vue.prototype.$toast2 = function(content, options) {
    console.log(content, options);
    Vue.$toast.success(content, options);
}