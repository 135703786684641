import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    TIPOMODIFICACAO: 'Gestão de Acessos'
}

export default [
    {
        name: ROUTER_NAMES.TIPOMODIFICACAO,
        path: '/gestao-acesso',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Gestão de Acessos',
            searchAlias: 'Gestão de Acessos',
            searchDescription: 'Veja todas os Acessos cadastrados',
            searchTerms: ['Gestão','Acessos'],
            hasRole: 'role/gestaoAcesso'
        },
        component: () => import('@/views/GestaoAcesso')
    },
]