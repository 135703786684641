import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
//funcionalidades
import relatorios from './modulos/relatorios';
import alterarSenha from "./modulos/alterarSenha";
import parametros from "./modulos/parametros";
import funcionarios from "./modulos/funcionarios";
import painel from "./modulos/painel";
import orgao from "./modulos/orgao";
import unidade from './modulos/unidade';
import gestaoAcesso from './modulos/gestaoAcesso';
import regiao from './modulos/regiao';
import unidadeMedida from './modulos/unidadeMedida';
import almoxarifado from './modulos/almoxarifado';
import item from './modulos/item';
import pac from './modulos/pac';
import licitacao from './modulos/licitacao';
import documento from './modulos/documento';
import paragrafo from './modulos/paragrafo';

Vue.use(VueRouter)

const routes = [
    ...relatorios,
    ...alterarSenha,
    ...parametros,
    ...funcionarios,
    ...painel,
    ...orgao,
    ...unidade,
    ...gestaoAcesso,
    ...regiao,
    ...unidadeMedida,
    ...almoxarifado,
    ...item,
    ...pac,
    ...licitacao,
    ...documento,
    ...paragrafo,
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        meta: {
            title: 'Sem Autorização',
            unauthorized: true,
        },
        component: () => import('@/views/Unauthorized')
    },
    {
        name: 'Login',
        path: '/login',
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const {meta} = to;

    function isGoingToLoginPage() {
        return meta.hasOwnProperty('loginPage') && meta.loginPage;
    }

    function isGoingToUnauthorizedPage() {
        return meta.hasOwnProperty('unauthorized') && meta.unauthorized;
    }

    async function handleIsAuthenticated() {
        const defaultHomePage = await store.dispatch('auth/getDefaultHomePage');
        if (isGoingToLoginPage()) {
            next({
                name: defaultHomePage
            });
        } else {
            const authorizedLinks = [...store.getters['auth/authorizedLinks']];
            if (isGoingToUnauthorizedPage()) {
                next();
            } else {
                if (authorizedLinks.indexOf(to.path) > -1) {
                    next();
                } else {
                    if (to.path === '/') {
                        next({
                            name: defaultHomePage
                        })
                    } else {
                        next('/unauthorized')
                    }
                }
            }
        }
    }

    async function handleNotAuthenticated() {
        if (isGoingToLoginPage()) {
            next();
        } else {
            const defaultLoginPage = await store.dispatch('auth/getDefaultLoginPage');
            next({
                name: defaultLoginPage
            })
        }
    }

    if (store.getters['auth/isAuthenticated']) {
        await handleIsAuthenticated();
    } else {
        await handleNotAuthenticated();
    }

})

export default router
