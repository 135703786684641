
export default {
    namespaced: true,
    state: {
        opened: true
    },
    mutations: {
        SET_OPENED(state, opened) {
            state.opened = opened;
        }
    },
    actions: {
        async toggle({commit, state}) {
            commit('SET_OPENED', !state.opened);
        }
    }
}