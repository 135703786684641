<template>
  <v-autocomplete solo
                  placeholder="Busque itens do menu"
                  color="white"
                  flat
                  dense
                  ref="input"
                  item-text="name"
                  @change="executeAction"
                  return-object
                  hide-details
                  v-shortkey="['ctrl', 's']"
                  @shortkey.native="$refs.input.focus"
                  :items="items"
                  :open-on-clear="false"
                  :filter="filterFunction"
                  prepend-inner-icon="mdi-magnify">
    <template v-slot:item="{item, on, attrs}">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-content>
          <v-list-item-title>
            {{item.name}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{item.descricao}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn small icon>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>

  </v-autocomplete>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "GlobalSearch",
  computed: {

    ...mapGetters('auth',{'menuRoutes': 'routes'}),
    routes() {
      const self = this;
      const searchableRoutes = [];

      function isSearchableRoute(route) {
        return route.searchable;
      }

      function getRouterName(route) {
        return route.searchAlias || route.title;
      }

      function getRouterDescription(route) {
        return route.searchDescription || null;
      }

      function extractRoutes(routes) {
        routes.forEach(route => {
          if(route.hasOwnProperty('children')) {
            extractRoutes(route.children);
          } else if (isSearchableRoute(route)) {
            const {searchTerms} = route;
            if(searchTerms.length > 0) {
              searchableRoutes.push({
                name: getRouterName(route),
                url: route.link,
                descricao: getRouterDescription(route),
                searchTerms: searchTerms,
                filter(item, text) {
                  for(const term of item.searchTerms) {
                    if(term.toLowerCase().trim().indexOf(text) > -1) {
                      return true;
                    }
                  }
                  return false;
                },
                action(item){
                  self.$router.push(item.url)
                }
              })
            }
          }
        })
      }

      extractRoutes(this.menuRoutes);

      return searchableRoutes;
    },

    items() {
      return [
          ...this.routes.map(route => ({
            type: 'route',
            ...route
          })),
      ]
    }
  },
  methods: {
    filterFunction(item, text) {
      const textoTratado = text.toLowerCase().trim();
      return item.filter(item, textoTratado);
    },
    executeAction(item) {
      if(item) {
        item.action(item);
        this.$refs.input.clearableCallback();
        setTimeout(() => {
          this.$refs.input.blur();
        }, 300);
      }
    }
  }
}
</script>

<style scoped>

</style>