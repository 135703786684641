import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    PARAMETROS: 'Parâmetros'
}

export default [
    {
        name: ROUTER_NAMES.PARAMETROS,
        path: '/parametros',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Parâmetros',
            searchDescription: 'Veja todas os Parâmetros cadastrados',
            searchTerms: ['parametros'],
            hasRole: 'role/parametros'
        },
        component: () => import('@/views/Parametros')
    },
]