import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    PAINEL: 'Painel'
}

export default [
    {
        name: ROUTER_NAMES.PAINEL,
        path: '/painel',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            searchAlias: 'Painel',
            searchDescription: 'Dashboard - Painel',
            searchTerms: ['Dashboard','Painel'],
            hasRole: 'role/painel'
        },
        component: () => import('@/views/Painel')
    },
]