<template>
  <v-navigation-drawer app
                       v-model="opened"
                       color="white">

    <v-list class="pa-0">

      <v-list-item class="fundo-logo" style="height: 61px">
        <v-list-item-title align="center">
          <app-logo height="40px"/>
        </v-list-item-title>
      </v-list-item>
      <v-divider/>
    </v-list>
    <div class="ma-4 mb-3 d-flex align-center justify-start" style="height: 31px;">
      <p class="ma-0 body-2 field-name">
        <img src="../../assets/logo_cliente.png" alt="Detran - BA" style="width: 100%"/>
      </p>
    </div>
    <v-divider/>
    <v-list
        class="menu-list"
        nav
        dense>
      <v-list-group v-for="m of menu" :key="m.title" :value="false">
        <template v-slot:activator>
          <v-list-item-title>
            <v-icon color="#0CBAEF">{{m.icon}}</v-icon> {{ m.title }}
          </v-list-item-title>
        </template>

        <v-list-item link class="ml-6" v-for="menuCadastroItem of m.childrens" :prepend-icon="menuCadastroItem.icon"
                     :to="menuCadastroItem.link"
                     :key="menuCadastroItem.link">
          <v-list-item-title>
            <v-icon color="#0CBAEF">{{menuCadastroItem.icon}}</v-icon> {{ menuCadastroItem.title }}
          </v-list-item-title>

        </v-list-item>

      </v-list-group>

    </v-list>
    <v-list class="pa-0">

      <v-divider/>
      <v-list-item @click="onClickLogout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Sair
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import GlobalSearch from "./GlobalSearch";
import AppLogo from "./AppLogo";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AppMenu",
  components: {AppLogo},
  computed: {
    ...mapGetters('auth',['user','isAuthenticated', 'menu', 'authorizedLinks']),
    opened: {
      get () {
        return this.$store.state.menu.opened
      },
      set(value) {
        this.SET_OPENED(value);
      }
    },
    userName: {
      get() {
        return this.user.nome;
      }
    }
  },
  methods: {
    ...mapMutations('menu',['SET_OPENED']),
    ...mapActions('auth',['doLogout', 'getDefaultLoginPage']),
    async onClickLogout() {
      await this.doLogout();
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .fundo-logo {
      background-color: #075084 !important;
    }
    .menu-list {
      flex: 1;
      overflow: auto;
    }

  }

  .field-name {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}
</style>