import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    UNIDADEMEDIDA: 'UnidadeMedida'
}

export default [
    {
        name: ROUTER_NAMES.UNIDADEMEDIDA,
        path: '/unidadeMedida',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Unidade de Medida',
            searchAlias: 'Unidade de Medida',
            searchDescription: 'Veja todas as Unidades de Medida cadastradas',
            searchTerms: ['Unidade de Medida'],
            hasRole: 'role/unidadeMedida'
        },
        component: () => import('@/views/UnidadeMedida')
    },
]