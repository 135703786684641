import Vue from 'vue';
import axios from "axios";
import VueAxios from "vue-axios";
import store from '../store';

export const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API
})

const mensagensLogout = [
    '[Controller-Erro]: Credencial não informada!',
    '[Controller-Erro]: Sua sessão expirou, efetue o login novamente!'
]

axiosInstance.interceptors.response.use(
    async (response) => {
        await store.restored;

        if(!response.data.success && mensagensLogout.indexOf(response.data.msg) > -1) {
            await store.dispatch('auth/doLogout');
            return response;
        }

       const authToken = store.getters["auth/token"];
        if(authToken && response.data.token != null) {
            store.commit("auth/SET_TOKEN", response.data.token);
        }

        return response;
    },
)

axiosInstance.interceptors.request.use(
    async request => {
        await store.restored;
        const authToken = store.getters["auth/token"];
        if(authToken) {
            
            request.url += `${request.url.indexOf('?') === -1 ? '?' : '&'}tk=${authToken}`;
            //console.log(request.url);
        }

        return request;
    }
)

Vue.use(VueAxios, axiosInstance);