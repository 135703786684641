
export const ROUTER_NAMES = {
    RELATORIO_LOG_COMUNICACAO: 'RelatorioLogComunicacao',
    RELATORIO_CONTRATO: 'RelatorioContrato',
    RELATORIO_VIG_CONTRATO: 'RelatorioVigenciaContrato',
}

export default [
    {
        name: ROUTER_NAMES.RELATORIO_LOG_COMUNICACAO,
        path: '/relatorios/logComunicacao',
        meta: {
            title: 'Log de Comunicação'
        },
        component: () => import('@/views/Relatorios/RelatorioLogComunicacao')
    },
    {
        name: ROUTER_NAMES.RELATORIO_CONTRATO,
        path: '/relatorios/contrato',
        meta: {
            title: 'Contrato'
        },
        component: () => import('@/views/Relatorios/RelatorioContrato')
    },    
    {
        name: ROUTER_NAMES.RELATORIO_VIG_CONTRATO,
        path: '/relatorios/vigenciaContratos',
        meta: {
            title: 'Contratos a Vencer'
        },
        component: () => import('@/views/Relatorios/RelatorioVigenciaContrato')
    },    
]