import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    PARAGRAFO: 'Prompts'
}

export default [
    {
        name: ROUTER_NAMES.PARAGRAFO,
        path: '/prompt',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Prompts',
            searchAlias: 'Prompt',
            searchDescription: 'Veja todas os Prompts cadastrados',
            searchTerms: ['Prompt'],
            hasRole: 'role/prompt'
        },
        component: () => import('@/views/Paragrafo')
    },
]