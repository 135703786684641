<template>
<img src="../../assets/logo-branca.png" alt="Relacionemento da Cadeia Produtivao" :style="{height}"/>
</template>

<script>
export default {
  name: "AppLogo",
  props: ['height']
}
</script>

<style scoped>

</style>