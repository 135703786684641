import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export const THEME_COLORS = {
  base: '#f5f5f5',
  primary: '#3F51B5',
  secondary: '#00BCD4',
  accent: '#00BCD4',
  error: '#FF5252',
  info: '#3F51B5',
  success: '#388E3C',
  warning: '#FFC107',
  alert: {
    confirmButtonColor: '#3F51B5',
    cancelButtonColor: '#4E4E4E'
  }
}

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        ...THEME_COLORS
      },

    },
  },
    lang: {
      locales: { pt },
      current: 'pt',
    },
});
