import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    DOCUMENTO: 'Documentos'
}

export default [
    {
        name: ROUTER_NAMES.DOCUMENTO,
        path: '/documento',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Documentos',
            searchAlias: 'Documentos',
            searchDescription: 'Veja todas os Documentos cadastrados',
            searchTerms: ['Documento'],
            hasRole: 'role/documento'
        },
        component: () => import('@/views/Documento')
    },
]