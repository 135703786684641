import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    PAC: 'Pac'
}

export default [
    {
        name: ROUTER_NAMES.PAC,
        path: '/pac',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Plano de Aquisição',
            searchAlias: 'Plano de Aquisição',
            searchDescription: 'Veja todas os Itens do Plano de Aquisição cadastrados',
            searchTerms: ['Plano de Aquisição'],
            hasRole: 'role/pac'
        },
        component: () => import('@/views/Pac')
    },
]