import {findItemsDominio, ItemDeDominio} from "../../api/itemsDominio";

export default {
    namespaced: true,
    state: {
        ...Object.values(ItemDeDominio).reduce((obj, value) => {
            return {
                ...obj,
                [value]: {
                    dirty: false,
                    loading: false,
                    error: false,
                    items: []
                }
            }

        }, {})
    },
    mutations: {
        SET_LOADING(state, {loading, itemDominio}) {
            state[itemDominio].loading = loading;
        },
        SET_ITEMS(state, {items, itemDominio}) {
            state[itemDominio].items = items;
        },
        SET_DIRTY(state, {dirty, itemDominio}) {
            state[itemDominio].dirty = dirty;
        },
        SET_ERROR(state, {error, itemDominio}) {
            state[itemDominio].error = error;
        }

    },
    actions: {
        async fetch({commit, state}, {itemDominio, forceRefresh = false}) {

            if (!forceRefresh && state[itemDominio].dirty) return state[itemDominio].items;

            try {
                commit('SET_LOADING', {loading: true, itemDominio});
                commit('SET_ERROR', {error: false, itemDominio});
                const dados = await findItemsDominio(itemDominio);
                commit('SET_ITEMS', {items: dados, itemDominio});
                commit('SET_DIRTY', {dirty: true, itemDominio});
            } catch (err) {
                console.error(err);
                commit('SET_ERROR', {error: true, itemDominio});

            } finally {
                commit('SET_LOADING', {loading: false, itemDominio});

            }
            return state[itemDominio].items;


        }
    }
}