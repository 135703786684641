import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ITEM: 'Item'
}

export default [
    {
        name: ROUTER_NAMES.ITEM,
        path: '/item',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Item',
            searchAlias: 'Item',
            searchDescription: 'Veja todas os Itens cadastradas',
            searchTerms: ['Item'],
            hasRole: 'role/item'
        },
        component: () => import('@/views/Item')
    },
]