import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    FUNCIONARIOS: 'Usuários'
}

export default [
    {
        name: ROUTER_NAMES.FUNCIONARIOS,
        path: '/funcionarios',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Usuários',
            searchDescription: 'Veja todas os Usuários cadastrados',
            searchTerms: ['funcionarios'],
            hasRole: 'role/funcionarios'
        },
        component: () => import('@/views/Usuarios')
    },
]