import Vue from "vue";
import moment from 'moment';

moment.locale('pt',{
    week: {
        dow: 1
    },
    weekdays: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sabado']
})

Vue.use(require('vue-moment'));