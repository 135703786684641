import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ALMOXARIFADO: 'Almoxarifado'
}

export default [
    {
        name: ROUTER_NAMES.ALMOXARIFADO,
        path: '/almoxarifado',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Almoxarifado',
            searchAlias: 'Almoxarifado',
            searchDescription: 'Veja todas os Almoxarifados cadastrados',
            searchTerms: ['Almoxarifado'],
            hasRole: 'role/almoxarifado'
        },
        component: () => import('@/views/Almoxarifado')
    },
]