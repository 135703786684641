import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ORGAO: 'Órgão'
}

export default [
    {
        name: ROUTER_NAMES.ORGAO,
        path: '/orgao',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Órgãos',
            searchAlias: 'Órgãos',
            searchDescription: 'Veja todas os Órgãos cadastrados',
            searchTerms: ['Órgão'],
            hasRole: 'role/orgao'
        },
        component: () => import('@/views/Orgao')
    },
]