import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    LICITACAO: 'Contratacao'
}

export default [
    {
        name: ROUTER_NAMES.LICITACAO,
        path: '/contratacao',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Contratações',
            searchAlias: 'Contratação',
            searchDescription: 'Veja todas as Contratações cadastradas',
            searchTerms: ['Contratação'],
            hasRole: 'role/contratacao'
        },
        component: () => import('@/views/Licitacao')
    },
]